<template>
  <div style="height: 100%;background: #f7f8fa;">
    <van-nav-bar :title="titel" :z-index="999" :fixed="true" left-arrow @click-left="back" />
    <div style="padding:46px 0px;">
      <div
        class="pai_list"
        :class=" item.onDefault ? 'biankuang' : ''"
        v-for="(item,idx) in list"
        :key="idx"
        @click="gengai(item)"
      >
        <div style="overflow: hidden;">
          <div class="left chepai">{{item.carNo}}</div>
          <div class="right renzheng" @click.stop="renzhen(item)">
            <span v-if="item.auth==0" style="color: #000093;">去认证</span>
            <span v-if="item.auth==1" style="color: #000093;">待认证</span>
            <span v-if="item.auth==2">已认证</span>
            <span v-if="item.auth==3" style="color: red;">认证未通过</span>
          </div>
        </div>
        <div style="overflow: hidden;margin-top: 10px;">
          <div class="left" style="width: 45px;height: 45px;">
            <img class="chexinimg" :src="item.carBrandLogo" alt />
          </div>
          <div class="left che_h2">
            <div class="c_h1">
              {{item.carBrand}}-{{item.carModel}}
              <span class="moren" v-if="item.onDefault">默认</span>
            </div>
            <!-- <div class="c_h2">2018款2.0T 双离合 尊享版</div> -->
          </div>
        </div>
        <div class="shanchu">
          <span @click.stop="shanchu(item)">删除</span>
        </div>
      </div>
    </div>
    <div class="bott">
      <div class="bott_c" @click="tianjia">添加爱车</div>
    </div>
    <van-empty v-if="list.length < 1" description="请添加车型~" />
    <van-popup class="ctisi" v-model="show" closeable>
      <h2 style="font-size: 20px;">尊敬的客户：</h2>
      <div style="text-indent:2em;line-height: 26px; margin-top: 5px;">
        欢迎注册您的爱车，我们将竭诚为您服务！在此之前请确认您现在所在的门店，<span style="color: red;font-size: 19px;">{{shangjia.name}}。</span>如果正确请点击"继续注册"，否则请点击"更换门店"
      </div>
      <van-button class="bost" type="primary" @click="tosig">继续注册</van-button>
      <van-button class="bost" type="danger" @click="tomengdian">更换门店</van-button>
    </van-popup>
  </div>
</template>

<script>
import userModel from "../../api/user";
import { Dialog } from "vant";
// import preventBack from "vue-prevent-browser-back"; //组件内单独引入
export default {
  // mixins: [preventBack], //注入
  data() {
    return {
      loading: false,
      show: false,
      shangjia: JSON.parse(sessionStorage.getItem("shangjia")),
      isok: this.$route.query.isok,
      list: [],
      titel: "更换车型"
    };
  },

  methods: {
    renzhen(item) {
      window.location.href =
        "/authentication?id=" +
        item.id +
        "&auth=" +
        item.auth +
        "&chexin=" +
        item.carBrand +
        "-" +
        item.carModel;
    },
    tianjia() {
      // this.$router.push({
      //   path: "model_list"
      // });
      if(this.shangjia.id == 208 ||this.shangjia.id == 484){
           window.location.href = "/authentication?auth=" + 0;
      this.show = false;
      }else{
        this.show = true;
      }
      
      // window.location.href = "/authentication?auth=" + 0;
    },
    tosig() {
      window.location.href = "/authentication?auth=" + 0;
      this.show = false;
    },
    tomengdian() {
      this.$router.push({
        path: "Store_list",
        query: {
          isok: false,
          title:'点击更换门店'
        }
      });
      this.show = false;
    },
    onLoad() {
      // const loading = this.$toast.loading({
      //   mask: true,
      //   duration: 0,
      //   message: "加载中..."
      // });
      userModel
        .chepailist({ gid: this.shangjia.id })
        .then(e => {
          // loading.clear();
          this.list = e.data;
          this.list.forEach((item, i) => {
            if (item.onDefault) {
              sessionStorage.setItem("chexin", JSON.stringify(item));
            }
          });
        })
        .catch(() => loading.clear());
    },
    //更改
    gengai(item) {
      let data = {
        id: item.id
      };
      const loading = this.$toast.loading({
        mask: true,
        duration: 0,
        message: "加载中..."
      });

      userModel
        .Default(data)
        .then(e => {
          sessionStorage.setItem("chexin", JSON.stringify(item));
          loading.clear();
          sessionStorage.setItem("isok2", "true");
          if (this.isok) {
            this.$router.go(-1);
          }
          this.onLoad();
        })
        .catch(() => loading.clear());
    },
    //删除
    shanchu(item) {
      Dialog.confirm({
        title: "提示",
        message: "是否删除该车辆信息？"
      })
        .then(() => {
          let data = {
            id: item.id
          };
          userModel
            .chexindelete(data)
            .then(e => {
              sessionStorage.removeItem("chexin");
              this.onLoad();
            })
            .catch(() => loading.clear());
        })
        .catch(() => {
          // on cancel
        });
    },
    back() {
      // this.$router.go(-1);
      this.$router.push({
        path: "user"
      });
    }
  },
  created() {
    if (this.$route.query.titel != undefined) {
      this.titel = this.$route.query.titel;
    }
    this.onLoad();
  },
  mounted() {
    window.addEventListener("pageshow", function(e) {
      // 通过persisted属性判断是否存在 BF Cache
      if (e.persisted) {
        location.reload();
      }
    });
  }
};
</script>

<style lang="less" scoped>
.pai_list {
  margin: 15px 15px;
  background: #fff;
  overflow: hidden;
  padding: 5px 15px 10px 12px;
  border-radius: 5px;
}
// 底部
.bott {
  height: 55px;
  width: 100%;
  background: #fff;
  position: fixed;
  bottom: 0;
  padding: 0 12px;
}
.bott_c {
  margin-top: 10px;
  font-size: 14px;
  background: #df3447;
  color: #fff;
  text-align: center;
  font-weight: bold;
  padding: 7px 18px;
  border-radius: 19px;
}
.chepai {
  color: #ffffff;
  letter-spacing: 0;
  text-align: center;
  height: 26px;
  line-height: 26px;
  // width: 82px;
  padding: 0 7px;
  background: url(../../assets/img/chepaibj.png) no-repeat;
  background-size: 100% 100%;
  color: #fff;
  font-size: 15px;
}
.moren {
  font-size: 12px;
  background: #ffd2d2;
  color: #ff7575;
  line-height: 16px;
  padding: 0 3px;
  display: inline-block;
}
.renzheng {
  font-size: 12px;
  color: #000;
  font-weight: 600;
}
.shanchu {
  line-height: 16px;
  font-size: 14px;
  color: red;
  text-align: right;
}
.chexinimg {
  width: 45px;
  height: 45px;
}
.che_h2 {
  margin-left: 10px;
  margin-top: 18px;
}
.c_h1 {
  font-size: 15px;
  font-weight: bold;
}
.c_h2 {
  font-size: 12px;
  margin-top: 3px;
  color: #666;
  line-height: 20px;
}
.biankuang {
  border: 2px solid #dc143c;
}
// 弹窗
.ctisi {
  width: 68%;
  font-size: 15px;
  color: #000;
  padding: 20px 25px;
border: 2px solid #dcdcdc;
}
.bost {
  height: 30px;
  
  border-radius: 8px;
  width: 90%;
  margin: auto;
  display: block;
  margin-top: 15px;
  font-size: 15px;
}

// .pai_list:nth-of-type(n + 2) {
//   margin-top: 15px;
// }
</style>
<style>
.van-overlay {
  /* background-color: rgba(0, 0, 0, 0); */
}
</style>
